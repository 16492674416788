<template>
  <van-cell-group>
    <van-search v-model="keywords" placeholder="Search" />
    <div v-for="(item, index) in list" :key="index">
      <van-cell
        v-show="
          filter(keywords, item.tradeNumber, item.deviceCode, item.nodeName)
        "
        is-link
        :title="item.tradeNumber"
        @click="toDetail(item.tradeNumber)"
      >
        <template #label>
          <div>{{ item.deviceCode }}-{{ item.nodeName }}</div>
          <div>{{ item.vmTime }}</div>
        </template>
      </van-cell>
    </div>
  </van-cell-group>
</template>
<script>
import util from '@/utils/util'

export default {
  data() {
    return {
      keywords: '',
      list: [],
    }
  },
  created() {
    this.listZkhReplenishLog()
  },
  methods: {
    filter: util.filter,
    async listZkhReplenishLog() {
      const { data } = await this.$api.listZkhReplenishLog()
      console.log('listZkhReplenishLog===>', data)
      this.list = data
    },
    toDetail(tradeNumber) {
      this.$router.push({ path: '/recordDetail', query: { tradeNumber } })
    },
  },
}
</script>